.userLogin {
  /* @import "../../assets/css/puliceStyle.css"; */
}
.userLogin .newTitleTwo {
  background: #fff;
}
.userLogin input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset !important;
}
.userLogin .pageTitle {
  font-size: 0.48rem;
  font-weight: Bold;
  margin-bottom: 1.2rem;
}
.userLogin .pageTitle p {
  font-size: 0.24rem;
  margin-top: 0.44rem;
}
.userLogin .isShowPwd {
  display: inline-block;
  float: right;
  margin-top: 0.2rem;
  width: 0.34rem;
  height: 0.28rem;
}
.userLogin .isShowPwd img {
  width: 100%;
  height: 100%;
}
.userLogin .linkLIne {
  font-size: 0.26rem;
  color: #333;
  margin-top: 0.64rem;
}
.userLogin .body1StyleInputStyle {
  border: 0px;
  font-size: 0.28rem;
  width: 80%;
  line-height: 0.8rem;
}
.userLogin .bodyStyleDivStyleP {
  margin: 0.56rem 0 0.2rem 0;
}
.userLogin .bodyStyleDivStyle {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.1rem;
}
.userLogin .body1Style {
  padding: 0.3rem;
}
.userLogin .bottom1 {
  position: fixed;
  width: 100%;
  bottom: 0.2rem;
}
.userLogin .bottom1 p {
  margin-left: 0.3rem;
  font-size: 0.26rem;
  color: #333;
}
